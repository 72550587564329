amplify-authenticator {
  
  --amplify-internal-button-disabled-border-color: gray;
  --amplify-internal-button-disabled-background-color: gray;
  --amplify-internal-button-disabled-color: gray;
  --amplify-internal-button-loading-background-color: gray;
  --amplify-internal-button-loading-border-color: gray;
  --amplify-internal-button-loading-color: gray;
  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-hover-border-color: transparent;
  --amplify-components-button-link-disabled-border-color: gray;
  --amplify-components-button-link-loading-background-color: gray;
  --amplify-components-button-link-loading-border-color: gray;
  --amplify-components-button-link-loading-border-color: gray;
  --amplify-components-button-link-loading-color: gray;
  --amplify-components-tabs-item-active-color: white;
  --amplify-components-tabs-item-active-background-color: var(--primary-color);
  --amplify-components-tabs-item-hover-color: #212529;
  --amplify-components-authenticator-state-inactive-background-color: black;
  --amplify-components-tabs-item-border-color: var(--primary-300);
  --amplify-components-tabs-item-active-border-color: var(--primary-300);
  --amplify-components-tabs-item-color: var(--gray-500);
  --amplify-components-heading-color: white;
  --amplify-components-button-color: var(--text-color);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  [data-amplify-authenticator] {
    
    position: relative;
    top: 50%;
    transform: translate(0,-50%);
    
    [data-amplify-router] {
      background-color: var(--surface-0);
      box-shadow: 0 0.5em 1em 0.25em rgba(0,0,0,0.5);
      border-color: var(--surface-0);
    }
  }
  
  amplify-form-field {
    .amplify-label {
      color: var(--gray-100);
    }

    .amplify-input {
      background: black;
      color: white;
    }

  }
}

button[amplify-button].amplify-button--primary {
  background: var(--primary-color);
  color: var(--text-color);
  font-weight: bold !important;
  text-transform: uppercase;

  
  &:hover {
    background: #43a5f4;
    color: #212529;
    border-color: #43a5f4;
  }
}

button[amplify-button].amplify-button--link {
  color: var(--primary-color);
  
  &:hover {
    background-color: none;
    color: white;
  }
}


amplify-tabs {

  .amplify-tabs-item {
    &:hover {
      background: #43a5f4;
    }

    &:focus {
      color: white;
    }
  }
}

[data-amplify-authenticator] [data-state=inactive]:not(:hover) {
  border-color: var(--gray-800);
  
}


/////////////////////////////
// logo
/////////////////////////////

div[data-amplify-authenticator]:before {
  content: '';
  background: rgb(0,0,0);
  background: linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%);
  box-shadow: none;
  position: fixed;
  width: 100%;
  height: 15em;
  bottom: 100%;
  margin-bottom: 1em;
}

div[data-amplify-authenticator]:after {
  content: '';
  position: fixed;
  margin: auto;
  width: 100%;
  bottom: 110%;
  height: 4em;
  color: white;
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  line-height: 5.5;
  background: transparent url('../assets/GlobalECCO.png') center/contain no-repeat;
}


