@import "./styles/_colors.scss";
@import "./styles/_sizes.scss";
@import "./styles/_button.scss";
@import "./styles/_inputs.scss";
@import "./styles/_image.scss";
@import "./styles/_tabmenu.scss";
@import "./styles/_tabview.scss";
@import "./styles/_table.scss";
@import "./styles/_password.scss";
@import "./styles/_fieldset.scss";
@import "./styles/_carousel.scss";
@import "./styles/_dialogs.scss";
@import "./styles/_event_creation.scss";
@import '~@aws-amplify/ui-angular/theme.css';
@import "./styles/_amplify.scss";

html {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent url('./assets/background.jpg') center/cover no-repeat;
  color: #eee;
}

// 1-off classes for convenience

.margin-auto {
  margin: auto !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-1 {
  margin: 0.25em !important;
}

.margin-2 {
  margin: 0.5em !important;
}

.margin-3 {
  margin: 1em !important;
}

.margin-4 {
  margin: 2em !important;
}

.margin-0-horizontal {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.margin-1-horizontal {
  margin-left: 0.25em !important;
  margin-right: 0.25em !important;
}

.margin-2-horizontal {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}

.margin-3-horizontal {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.margin-4-horizontal {
  margin-left: 2em !important;
  margin-right: 2em !important;
}

.margin-0-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.margin-1-vertical {
  margin-top: 0.25em !important;
  margin-bottom: 0.25em !important;
}

.margin-2-vertical {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.margin-3-vertical {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.margin-4-vertical {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.vertical-align-super {
  vertical-align: super;
}

.vertical-align-middle {
  vertical-align: middle;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none !important;
}

.display-initial {
  display: initial !important;
}

.display-unset {
  display: unset !important;
}

.display-block {
  display: block !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

////////////////////

.padding-0 {
  padding: 0 !important;
}

.padding-1 {
  padding: 0.25em !important;
}

.padding-2 {
  padding: 0.5em !important;
}

.padding-3 {
  padding: 1em !important;
}

.padding-4 {
  padding: 2em !important;
}

.padding-0-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-1-horizontal {
  padding-left: 0.25em !important;
  padding-right: 0.25em !important;
}

.padding-2-horizontal {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.padding-3-horizontal {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.padding-4-horizontal {
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.padding-0-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-1-vertical {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
}

.padding-2-vertical {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.padding-3-vertical {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.padding-4-vertical {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}

.vertical-align-super {
  vertical-align: super;
}

.vertical-align-middle {
  vertical-align: middle;
}

/////////////////

.text-center {
  text-align: center !important;
  
  * {
    text-align: center !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.no-pointer-events {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-size-med {
  font-size: 1em;
}

.font-size-large {
  font-size: 2em;
}

.font-bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.vertical-center {
  top: 50%;
  transform: translate(0,-50%);
}

.white-icon {
  color: white;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100% !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll-y {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.expand {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute !important;

  &.with-border {
    top: 50px;
  } 
}

.expand-1 {
  top: 0.25em;
  left: 0.25em;
  right: 0.25em;
  bottom: 0.25em;
  position: absolute !important;
}

.no-user-select {
  user-select: none;
}

// generic container styles

.standard-container {
  margin: auto;
  text-align: center;
  background-color: var(--default-container);
  box-shadow: 0 0.5em 1em 0.25em rgba(0,0,0,0.5);
  transition: background-color 0.25s, transform 0.25s, box-shadow 0.25s;
  position: relative;
  padding: var(--standard-container-padding-2);

  &.blank {
    background: transparent;
    box-shadow: none;
  }

  &.fade {
    background: rgb(0,0,0);
    background: linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%);
    box-shadow: none;
  }

  .content-header {
    background: black;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: var(--standard-container-padding-half);
    padding-bottom: var(--standard-container-padding-half);
    height: calc(var(--standard-container-header-height) + var(--standard-container-padding-half));
  }
  
  .content-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: var(--standard-container-padding-1);
    height: var(--standard-container-footer-height);
    text-align: center;
    
    &.height-L {
      height: var(--standard-container-footer-height-double);
      padding: 0em;
    }
  }

  .content-message {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    height: 4em;
    top: 100%;
    top: calc(100% + var(--standard-container-padding-1));
    background-color: var(--red-700);
    color: white;
    padding: {
      top: 1em;
      bottom: 1em;
    }
    transition: all 0.5s;
    
    &:empty {
      height: 0;
      padding: 0;
      color: transparent;
    }
  }
  

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(var(--standard-container-footer-height-and-padding) + var(--standard-container-padding-half));    
    bottom: var(--standard-container-footer-height);
    overflow-y: auto;
    
    &.footer-L {
      bottom: var(--standard-container-footer-height-double);
    }

    @media screen and (min-width: 1800px) { 
      .left-section, .right-section {
        
        width: 50%;
        display: inline-block;
        vertical-align: top;
      }
    }

    &.no-footer {
      bottom: 0;
    }
    
  }
}

.standard-container.hoverable:hover {
  transform: scale(1.02);
  box-shadow: 0 2em 2em 0.25em rgba(0,0,0,0.5);
  cursor: pointer;
}

.standard-container.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.detail-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.detail-container {
  width: 80%;
  height: 80%;
  position: relative;
  top: 50%;
  transform: translate(0,-50%);
}

.half-size {
  width: 50%;
  height: 50%;
}

.navigation {
  transition: all 0.25s;
  position: fixed;
  top: var(--top-bar-height);
  bottom: 0;
  left: 0;
  width: var(--nav-bar-width);
}

@media screen and (max-width: 960px) { 
  .navigation {
    transition: all 0.25s;
    width: var(--nav-bar-width-small);
  }
}


.main-content {
  position: fixed;
  top: var(--top-bar-height);
  left: var(--nav-bar-width);
  right: 0;
  bottom: 0;
  padding: 1em;
}

@media screen and (max-width: 960px) {
  .main-content { 
    left: var(--nav-bar-width-small);
    
  }
}

.main-content-container {
  position: absolute;
  top: 0;
  left: 1em;
  right: 1em;
  bottom: 1em;
  text-align: center;
  overflow-y: auto;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: var(--top-bar-height);
  padding: 1em;
  
  span {
    cursor: pointer;
    margin: 0.25em;
    font-size: 2em;
    font-weight: bold;
  }
}

.side-buttons {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0.25em;

  > * {
    position: relative;
  }
}

.block {
  display: block;
}

.section-header {
  font-weight: bold;
  font-size: 1.25em;
}

#version-number {
  font-size: 0.75em;
  color: #ccc;
  position: fixed;
  bottom: 0em;
  left: 2px;
}

#logo {
  background: transparent url('./assets/GlobalECCO.png') center/contain no-repeat;

  &.header {
    width: 9em;
    height: 8em;
  }
}
