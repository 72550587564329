p-button .p-button, 
button[pButton] .p-button, 
button[pButton].p-button:not(.p-button-text) {


  background: var(--primary-color);
  color: var(--text-color);
  font-weight: bold;
  text-transform: uppercase;

  &.white {
    color: white;
  }
}

p-button.margin-3 {
  button {
    margin: 1em !important;  
  }
}

.horizontal-flex {
  p-button .p-button {
    width: 100%;
    display: inline-block;
  }
}

button[pButton].p-button {
  
  &.join-match {
    text-align: center;
    width: 100%;
    display: block;
    height: 100%;
    padding: 0
    
  }
}

p-button.full-width {
  button {
    width: 100%;
  }
}

p-button {
  
  &.small {
    margin: 0 !important;

    button {
      height: 1.5rem !important;
      width: 1.5rem !important;
      margin-bottom: 0.5em;
      span {
        font-size: 0.75em !important;
      }
    }
  }
  * {
    transition: 0.1s !important;
  }
}
