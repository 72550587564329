.inputText, .dropDown, .checkbox, .switch, .inputNumber, .readonly, .button, .password, .message, .spacer, .display-only, .mini-match-add-player, .label {

  &[futuretech][field]
  {
    position: relative;

    label {
      padding: 1em;
    }
    label ~ * {
      margin: auto;
    }

    &.column {

      label {
        display: block;
      }

      label ~ * {
        display: block;
      }
    }

    &.row {
      display: flex;

      label {
        flex: 1 1 0%;
        text-align: left;
      }

      label ~ * {
        flex: 1 1 0%;
        flex-direction: row-reverse;
        text-align: right;
        margin: 0.5em;
      }
    }

    &.align-center {
      text-align: center;
      label {
        text-align: center;
      }
      label ~ * {
        text-align: center;
      }
    }

    &.align-left {
      label {
        text-align: left;
      }
      label ~ * {
        text-align: left;
      }
    }

    &.align-right {
      label {
        text-align: right;
      }
      label ~ * {
        text-align: right;
      }
    }

    &.full-width {
      label, label ~ * {
        width: 100%;
      }
    }

    &.width-90 {
      label ~ * {
        width: 90%;
      }
    }

    &.half-width {
      label ~ * {
        width: 50%;
      }
    }

    &.three-quarter-width {
      label ~ * {
        width: 75%;
      }
    }

    &.row-lines {

      &::before, &::after {
        content: '';
        position: absolute;
        height: 1px;
        background: var(--gray-600);
        left: 0;
        right: 0;
      }

      &::before {
        top: -1px;
      }

      &::after {
        bottom: 0;
      }
    }

    &.fit-spacious {
      label ~ * {
        padding: 1em;
      }
    }

    &.message {
      .message {
        span {
          display: none;
        }

        &.password-entered span.password-entered {
          display: initial;
          color: red;
        }

        &.password-match span.password-match{
          display: initial;
          color: limegreen;
        }
      }

    }

    &.display-only {
      label ~ span {
        font-weight: bold;
        font-size: 1.5em;
      }

      label ~ span.none {
        font-weight: normal;
        color: transparent;
      }

    }

    &.label {
      label {
        padding: 0.25em;
      }

      &.big {
        label {
          font-size: 3em;
        }
      }

      &.medium {
        label {
          font-size: 1.5em;
        }
      }
    }

    &.spacer {

      &.top {
        &.big {
          padding-top: 2em;
        }
        &.medium {
          padding-top: 1.5em;
        }
        &.small {
          padding-top: 1em;
        }
      }

      &.bottom {
        &.big {
          padding-bottom: 2em;
        }
        &.medium {
          padding-bottom: 1.5em;
        }
        &.small {
          padding-bottom: 1em;
        }
      }
    }


  }

}

.mini-match-add-player {

  &[futuretech][field]
  {
    display: block;
  }
}

/////////////////////////////////////////////////////

.subheader[futuretech] {
  margin: {
    top: 1.5em;
    bottom: 1.5em;
  }

  label {
    width: 100%;
    text-align: center;
    font-size: 1.25em;
    font-weight: bold;
  }
}

///////////////////////////////////////
// changed inputs

input, p-dropdown {
  transition: box-shadow 0.25s;
  box-shadow: none;
  &.changed {
    box-shadow: 0 0 0 4px var(--red-500);
  }

  &.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 4px var(--red-400);
  }
}

p-checkbox {
  transition: box-shadow 0.25s;
  box-shadow: none;
  &.changed .p-checkbox {
    box-shadow: 0 0 0 4px var(--red-500);
  }
}


p-inputswitch {
  transition: box-shadow 0.25s;
  box-shadow: none;
  &.changed .p-inputswitch {
    box-shadow: 0 0 0 4px var(--red-500);
    border-radius: 30px;
  }
}

/////////////////////////////////////////////////////
// Switch-style checkbox
// .switch[futuretech] {
  p-checkbox.switch {
    .p-checkbox {
      background-color: black;
      width: 44px;
      border-radius: 22px;
      border: 1px solid #888;

      &.p-checkbox-checked {
        background-color: var(--primary-800);
        background-color: var(--indigo-600);
      }

      transition: background-color 0.25s;
    }

    .p-checkbox .p-checkbox-box {
      border-radius: 100%;
      position: relative;
      left: 0;
      transition: left 0.25s;
      background-color: var(--primary-900);
      border: 2px solid var(--primary-700);

      span.p-checkbox-icon {
        display: none;
      }

    }


    .p-checkbox .p-checkbox-box.p-highlight {
      left:22px;

    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
      background-color: var(--primary-500);
      border: 2px solid var(--primary-300);
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {}


  }

  p-checkbox.switch,
  p-inputSwitch {
    &.vertical {
      transform: matrix3d(0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 10, 0, 1);
    }

    &:not(.vertical) {
      .p-inputswitch {
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }


  p-checkbox label {
    position: absolute;
    width: 44px;
    height: 22px;
    cursor: pointer;
    opacity: 0;
  }
// }

/////////////////////////////////////////////////////
// dropdown field

.dropDown[futuretech] {
  p-dropdown .p-dropdown {
    width: 100%;
  }
}

/////////////////////////////////////////////////////

.inputText[futuretech] {

  input {
    padding: {
      left: 1em;
      right: 1em;
    }
  }
}

/////////////////////////////////////////////////////

.inputNumber.row[futuretech] {
  p-inputNumber input {
    text-align: right;
  }
}

/////////////////////////////////////////////////////

.readonly[futuretech] {
  .value {
    padding: 1em !important;
    margin: 0 !important;
    font-size: 1em;
    font-weight: bold;
  }
}

/////////////////////////////////////////////////////

.separator-line[futuretech] {
  width: 90%;
  height: 4px;
  background: var(--gray-800);
  margin: 2em;
}

/////////////////////////////////////////////////////
p-dropdown.role {
  .p-dropdown {
    width: 100%;
  }
}

/////////////////////////////////////////////////////

div.readonly {

  background-color: var(--gray-800);
  color: var(--gray-300);

  &.transparent {
    background-color: transparent;
  }

  label::after {
    content: "\e95f";
    font-size: 1rem;
    font-family: 'primeicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    margin-left: 1em;
    margin-right: 1em;
  }

}

p-autocomplete.player-search {
  span {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

input[pInputText]:not(.p-filled) {
  opacity: 0.5;
  font-style: italic;
}

/////////////////////////////////////////////////////

p-inplace[mini-match-list] {
  .p-inplace .p-inplace-display {
    // display: inline-block;
  }

  .p-inplace .p-inplace-display {
    font-size: 1.5em;
  }

  .p-component {

    font-size: 1em;
  }
}
