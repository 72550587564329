p-dynamicdialog {
  .p-dialog-header {
    background-color: black !important;
    padding: 0.5em !important;
    
  }

  .p-dialog-content {
    overflow: hidden;
  }
}