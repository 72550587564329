p-carousel[futuretech][game-screenshots] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .p-carousel, 
  .p-carousel-content, 
  .p-carousel-container,
  .p-carousel-items-container {
    height: 100%;
  }

  .p-carousel-item {
    height: 100%;
    background-color: black;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
      padding: 1em;
      margin: 0;
    }
  }
}