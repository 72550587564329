p-image[futuretech] {
  display: block;
  width: 100%;
  height: 100%;

  span {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .p-image-mask {

  }
}