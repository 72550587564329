p-tabmenu[futuretech][navigation] {
  ul[role="tablist"].p-tabmenu-nav {
    
    flex-direction: column;

    li[role="tab"] {

      // li styles
      // default
      & { 
        transition: all 0.25s;
        position: relative;
        display: block;
        left: 0px;
        margin: {
          top:1px;
          bottom: 1px;
        }
      }

      // hover state
      &:hover {
        left: var(--highlight-offset);

      }
      
      // selected state
      &.p-highlight {
      }

      &.p-highlight:hover {

      }

      //////////////////////
      
      // default a inside tab
      & a.p-menuitem-link {
        
        position: relative;
        transition: all 0.25s;
        overflow: initial;
        
        border-width: 0 0 1px 0;
        border-color: #121a25;
        
        border-radius: 0;
        
        border-top-right-radius: var(--highlight-border-radius);
        border-bottom-right-radius: var(--highlight-border-radius);
        
      }
      
      & a.p-menuitem-link:focus {
        // box-shadow: none;
        color: var(--primary-color);
        background-color: var(--primary-400);
      }

      &:hover a.p-menuitem-link {
        background-color: #304158;
        border-width: 0 0 4px 0;
      }
      
      &.p-highlight a.p-menuitem-link {
        
        background-color: var(--primary-color);
        border-bottom: 4px solid var(--primary-500);
        box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.5);
        color: black;
        
      }
      
      &.p-highlight:hover a.p-menuitem-link {
        background-color: var(--primary-400);
        border-color: var(--primary-300);

      }
      
      //////////////////////
      // pseudo element on a 
      & a.p-menuitem-link::after {
        content: '';
        background: #1f2d40;
        border-bottom: 1px solid #1f2d40;
        width: var(--highlight-offset);
        position: absolute;
        top: 0;
        bottom: -1px;
        left: var(--highlight-offset-negative);
        transition: all 0.25s;
      }
      
      &:hover a.p-menuitem-link::after {
        border-bottom: 4px solid #64B5F6;
        background-color: #304158 !important;
        border-width: 0 0 4px 0;
        bottom: -4px;
      }
      
      
      &.p-highlight a.p-menuitem-link::after {
        border-bottom: 4px solid var(--primary-500);
        background-color: var(--primary-color) !important;
        bottom: -4px;
      }
      
      &.p-highlight:hover a.p-menuitem-link::after {
        border-bottom: 4px solid var(--primary-300);
        background-color: var(--primary-400) !important;
      }

    }
    

  }
  
  .p-tabmenu-nav-content {
    overflow: initial !important;
  }
  
  
  .p-tabmenu .p-tabmenu-nav {
    border: none;
  }
}

@media screen and (max-width: 960px) {
  p-tabmenu[futuretech][navigation] {
    ul[role="tablist"].p-tabmenu-nav {
      
      li[role="tab"] {
        
        &:hover {
          left: 0;
        }
        
        a.p-menuitem-link {
          border-radius: 100%;
          width: 2em;
          height: 2em;
          margin: 0.5em;
          border: none;
          
          
          &::after {
            content: none;
          }
        }

        &.p-highlight a.p-menuitem-link {
          border: none;
        }
      }

      .p-menuitem-text {
        display: none;
      }

      .p-menuitem-icon {
        margin-left: -0.5em;
      }
    }
  }
}