:root {
  --top-bar-height: 8em;
  --nav-bar-width: 10em;
  --nav-bar-width-small: 2em;
  --game-list-item-height: 30em;
  --game-list-item-width: 30%;
  --game-list-item-width-small: 40%;
  --game-list-item-width-xsmall: 90%;
  --game-list-item-max-width: 20em;

  --standard-container-header-height: 2em;
  --standard-container-footer-height: 4em;
  --standard-container-footer-height-double: 6em;

  --standard-container-padding-1: 0.5em;
  --standard-container-padding-half: 0.25em;
  --standard-container-padding-2: 1em;

  --standard-container-footer-normal: 4em;
  --standard-container-footer-double: 2em;

  --standard-container-footer-height-and-padding: 3em;
  --standard-container-footer-height-and-padding-double: 6em;
  --highlight-offset: 10px;
  --highlight-offset-negative: -10px;

  --highlight-border-radius: 4px;
  --height-top-section: 20%;

  --media-query-split-container-screen-min-width: 1366px;

}