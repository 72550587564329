p-tabView[futuretech][authentication] {
  
  transition: all 0.15s;
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
  }
  
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: var(--surface-0);
    border-bottom: 10px var(--primary-500) solid;
    &:hover {
      background-color: var(--bluegray-800);
      color: white;
      border-bottom-color: var(--primary-300);
    }

  }

  ul.p-tabview-nav {
    display: flex;

    li {
      flex: 1 1 0%;

      .p-tabview-nav-link {
        transition: all 0.15s;
        border-radius: 8px 8px 0 0;
        position: relative;
        top: 8px;

        span {
          margin: auto;
        }
      }
    }

    li.p-highlight .p-tabview-nav-link {
      background-color: var(--primary-500);
      color: var(--bluegray-800);
      font-weight: bold;
      text-align: center;
      top: 0;

      &:hover {
        background-color: var(--primary-300);
      }
    }
  }
}