p-table[futuretech] {
  .p-datatable .p-datatable-thead > tr {

    & th {
      background-color: var(--primary-900);
      color: var(--bluegray-100);      
    }
    
    &:nth-child(2) th {
      background: var(--primary-400);
    }
  }

  .play-or-view i {
    cursor: pointer;

    i.play {
      display: initial;
    }
    
    i.view {
      display: none;
    }

    &.finished {
        
      i.play {
        display: none;
      }
      
      i.view {
        display: initial;
      }
    }
  }

  td.visibility {

    .visible {
      display: none;
    }

    .invisible {
      display: initial;
    }

    &.active {
      .visible {
        display: initial;
      }
      
      .invisible {
        display: none;
      }  
    }

  }
  .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tbody > tr > td {
    &.details, &.delete, &.play-or-view {
      width: 0;
    }
  }
  
  &[joined-player] .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
  }

  .p-datatable .p-datatable-tbody > tr.full-extent > td {
    padding: 0;
  }


  .p-datatable .p-sortable-column .p-sortable-column-icon,
  .p-datatable p-columnfilter .p-column-filter .p-column-filter-menu-button,
  .p-datatable p-columnfilter .p-column-filter .p-column-filter-clear-button {
    color: var(--bluegray-100);
    
    &:hover {
      color: white;
    }
    
    &:active {
      background-color: var(--bluegray-900);
    }
  }
  
  .p-datatable .p-sortable-column[aria-sort="ascending"], 
  .p-datatable .p-sortable-column[aria-sort="descending"] {
    background-color: var(--bluegray-900);
    
    &, .p-sortable-column-icon {
      color: white;
    }
    
    &:hover {
      background-color: var(--primary-800);
    }
  }
  
  .p-datatable th:not(.p-sortable-column) {
    cursor: default;
  }
  .p-datatable .p-datatable-header {
    // padding: 0;
    text-align: left;
    background-color: var(--primary-300);
    color: var(--bluegray-900);
    font-size: 1.25em;
  }

  th, td {
    &.button-only {
      width:3rem;
      text-align: center;
      padding: 0 !important;
    }
  }

  .p-datatable-footer {
    padding: 0;
  }

  &.small-header .p-datatable .p-datatable-header {
    padding: 0;
  }

  
  .p-datatable .p-datatable-tbody > tr {
    background-color: #15202e;

    &.not-enabled {
      background-color: #090e15;
      color: #555;
      
      &.hover:hover {
        background-color: #23334c;
      }

      td.icon img {
        opacity: 0.5;
      }
    }
  }


  tr.row-group-header.event-header {
    // background-color: var(--blue-500);
    position: relative;
    box-shadow: 0 0.5em 1em 0 rgba(0,0,0,0.5);

    > td {
      border: 0 solid var(--primary-500);
      border-width: 0.15em 0 0 0;
    }
    
    &.match {
      background-color: #037edf;
      
      &.locked-match {
        background-color: #06233a;
      }
    }
    
    
    &.open-match {
      background-color: #2f4665;
    }

    
    
    &.empty-match {
      background-color: #0f161f;
      color: #506581;
      font-style: italic;
    }
    
    &.button {
      cursor: pointer;
      
      &:hover {
        background-color: var(--primary-800);
      }
    }
  }

}

p-table[futuretech] {
  .table-title {
    text-align: center;
  }

}

.p-datatable .p-sortable-column:hover .p-column-filter .p-column-filter-menu-button {
  color: white;
}

p-table[guest-user-list] .p-datatable .p-datatable-tbody > tr > td {
  border: none;
  padding: 0;
}

joined-player[mini-match-list] {
  
  p-table[joined-player] .p-datatable .p-datatable-thead > tr th {
    background-color: var(--blue-800);
    color: white;

  }
  
  p-table[joined-player] .p-datatable .p-datatable-tbody > tr {
    background-color: var(--blue-900);
  }
}

p-table[mini-match-list],
p-table[guest-user-list] {
  .p-datatable .p-datatable-header {
    background-color: var(--primary-900);
  }
}

p-table[futuretech] .p-datatable .p-datatable-tbody > tr.hover:hover {
  background-color: var(--primary-800);
}

.p-datatable .p-datatable-thead > tr > th {
  border-color: #3c6485;
}

p-table[futuretech] {

  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th,
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border: none;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 0px;
  }
  
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 0 1px 0px;
  }
}

p-table[futuretech][public-matches] .p-datatable .p-datatable-header {
  border: 0px solid var(--default-container);
  border-width: 2em 0 0 0;
}

p-table[futuretech] .p-datatable .p-datatable-tbody > tr.rowgroup-footer {
  background-color: var(--blue-800);
}

p-table[futuretech] {
  tfoot.p-datatable-tfoot {
    td.empty {
      background-color:#090e15;
      font-weight: normal;
      font-style: italic;
      text-align: center;
    }
  }
}

p-table[futuretech][mini-match-list] {
  .p-datatable .p-datatable-tbody > tr.match.unlocked-match {
    background-color: var(--blue-500);
    position: relative;
  } 
}

p-table[futuretech][guest-user-list] {
  .p-datatable .p-datatable-tbody > tr {
    background-color: #1f2d40;
  } 
}

p-table[futuretech][mini-match-list],
p-table[futuretech][guest-user-list] {

  position: absolute;
  bottom: 0;
  top: 3em;
  left: 0;
  right: 0;

  .p-datatable {
    height: 100%;
    overflow: hidden;
  }

  .p-datatable-scrollable>.p-datatable-wrapper {
    height: calc(100% - 4em);
    // height: 40em;
  }
}

@media screen and (max-width: 960px) { 
  p-table[futuretech][mini-match-list],
  p-table[futuretech][guest-user-list] {

    position: initial;
    

    .p-datatable {
      height: auto;
      overflow: initial;
    }

    .p-datatable-scrollable>.p-datatable-wrapper {
      height: auto;
    }
  }
}

p-table[joined-player] {
  th, td {
    &.status {
      text-align: center;
    }
  }
}

joined-player[mini-match-list] {

  p-table[joined-player] {
    th, td {
      &.status {
        display: none;
      }
    }
  }
}
