p-password[futuretech] {

  &.align-center input {
    text-align: center
  }

  &.fit-spacious input {
    padding: 1em;
  }

  &.full-width {
    width: 100%;
    padding: 0 !important;

    .p-password, input {
      width: 100%;
    }
  }
}