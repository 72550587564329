p-fieldset[futuretech] {

  fieldset {
    width: 100%;
  }

  &.full-height fieldset {
    height: 100%;
  }

  &.text-left fieldset {
    text-align: left;
  }

  &.dark fieldset {
    background: var(--gray-900);
  }

  .p-fieldset .p-fieldset-legend {
    padding: 0;
    border: none;
  }


  &.horizontal-flex .p-fieldset-content {
    display: flex;
  }

  .p-fieldset .p-fieldset-content {
    padding: 0.25em;
  }
}