
.top-section, .bottom-left, .bottom-right {
  position: absolute;
  padding: 1em;
}

.top-section {
  top: 0;
  left: 0;
  right: 0;
  height: var(--height-top-section);
  overflow-y: hidden;
}

.bottom-left, .bottom-right {
  bottom: 0;
  width: 50%;
  height: calc(100% - var(--height-top-section));
}

.bottom-left {
  left: 0;
}

.bottom-right {
  right: 0;
}


@media screen and (max-width: 960px) { 
    
  .top-section, .bottom-left, .bottom-right {
    position: relative;
    width: 100%;
  }
    
  .top-section {
    height: var(--height-top-section);
    position: relative;
  }

  .bottom-left, .bottom-right {
    
    height: auto;
  }
}